import React,  {useContext, useState, useEffect} from "react";
import { GenGet,  GenPost } from "Loja/state/api";
import { CartContext } from "Ecommerce";
import { Alert, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MercadoPago from "Loja/components/compLoja/MercadoPago";
import ControladorCliente from "./ControladorCliente";
import ControladorEndereco from "./ControladorEndereco";
import ControladorFrete from "./ControladorFrete";
import ControladorUser from "./ControladorUsuario";

function FinalizarPedido() {
  const nav = useNavigate();
  const { cart, rmvCart, newCart, setcartEvent, appUrl, setLogging } = useContext(CartContext);
  const [loading, setLoading] = useState(true)
  const [possuiRetirada, setPossuiRetirada] = useState(false);
  const [data, setData] = useState({})
  
  const [cliente_ok, setClienteOk] = useState(false);
  const [user_ok, setUserOk] = useState(false);
  const [enderecos, setEnderecos] = useState();
  const [retirar, setRetirar] = useState(false);
  const [frete_isento, setFreteIsento] = useState(false);

  const [cupom, setCupom] = useState({
    codigo:''
  });
  const [cupomMsg, setCupomMsg] = useState(null);
  const [cupomValido, setCupomValido] = useState(false);


  const [payment, setPayment] = useState(false);
  const [orderData, setOrderData] = useState({});

  const [msg, setMsg] = useState(false);

  const subtotal = cart?.length
  ? cart.reduce((total, item) => total + parseFloat(item.valor), 0).toFixed(2)
  : '0.00';
  const desconto = cupomValido 
  ? cupom.tipo_desconto === 'valor' 
    ? cupom.valor_desconto 
    : parseFloat(subtotal) * (cupom.valor_desconto / 100)
  : 0;

  const totalFormatado = (parseFloat(subtotal) - parseFloat(desconto) + parseFloat((data.frete_valor || 0)) ).toFixed(2);
  const [validate, setValidate] = useState({});
  
  const handleCupom = (e) => {
    setCupom({
      ...cupom,
      codigo : e.target.value.toUpperCase()
    });
  }

  const handleRetirar = (e) =>{
    setRetirar(e.target.checked);

    if (e.target.checked){
      setValidate({
        ...validate, 
        endereco:true,
        frete:true
      })

    } else {
      setValidate({
        ...validate, 
        endereco:false,
        frete:false
      })
    }
  }

  async function getUser() {
    try {
      const response = await GenGet(`api/user-data`);
      const params_response = await GenGet(`api/params/fretes`);

      if (params_response.frete_isento === 1 && (
        params_response.frete_isento_tipo === 'regiao_retirada' ||
        params_response.frete_isento_tipo === 'valor_retirada' ||
        params_response.frete_isento_tipo === 'regiao_e_valor_retirada' ||
        params_response.frete_isento_tipo === 'regiao_ou_valor_retirada' )){
          setPossuiRetirada(true);  
      }
  
      if (response && response.user && response.user.cliente) {

        setData(prevData => ({
          ...prevData,
          cliente: response.user.cliente,
          user:response.user
        }));

        setClienteOk(true);
        setUserOk(true);
        setValidate(prevValidate => ({
          ...prevValidate,
          cliente: true,
          user:true
        }));
        if (response.user.cliente.enderecos && response.user.cliente.enderecos.length > 0) {
          setEnderecos(response.user.cliente.enderecos);
        }
      } else {

      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  const removeItem = async (id, produto_id) => {
    try {
      await GenPost(`api/ucartitens/${id}`);
      const novoCart = cart.filter((item) => item.id !== id);
      newCart(novoCart);
      setcartEvent(true);
    } catch (error) {
      console.error(error);
    }
    rmvCart(produto_id);
  };

useEffect(()=>{
  getUser();
},[])

useEffect(()=>{
  if(frete_isento === true ){
    setValidate({
      frete:true,
      endereco:true
    })
  } 
},[frete_isento])



const validateForm = () => {

  const validateEndereco = (data) => {
    if (!data || !data.endereco) {
      return false;
    }
    const requiredFields = ['endereco', 'numero', 'cidade', 'cep', 'estado'];
    for (let field of requiredFields) {
      if (!data.endereco[field] || data.endereco[field].trim() === "") {
        return false;
      }
    }
    return true;
  };
  
  const validateCliente = (data) => {
    // Verifica se o objeto cliente existe
    if (!data || !data.cliente) {
      return false;
    }
  
    const requiredFields = ['nome', 'sobrenome', 'cpf', 'telefone'];
    for (let field of requiredFields) {
      if (!data.cliente[field] || data.cliente[field].trim() === "") {
        return false;
      }
    }
    return true;
  };

  const validateUser = (data) => {
    // Verifica se o objeto cliente existe
    if (!data || !data.user) {
      return false;
    }
    if (user_ok){
      return true;
    }
    const requiredFields = ['name', 'email', 'password', 'password_confirmation'];
    for (let field of requiredFields) {
      if (!data.user[field] || data.user[field].trim() === "") {
        setTimeout(() => {
          setMsg('Todos os campos de Usuário são nescessários.');
        }, 3000);
        console.log('Não valida campo faltando');
        return false;
      }
    }

    if (data.user['password'] !== data.user['password_confirmation']){
      setTimeout(() => {
        setMsg('Senhas não conferem');
      }, 3000);
      console.log('Não valida senha');
      return false;
    }

    return true;
  };

  const isClienteValid = validateCliente(data);
  if (!isClienteValid) {
    setMsg('Todos os campos de cliente são obrigatórios.');
    setTimeout(() => {
      setMsg(null);
    }, 3000);
    return false;  // Certifique-se de retornar false para interromper a execução
  }

  const isUserValid = validateUser(data);
  if (!isUserValid) {
    
    return false;
  }

  const isEnderecoValid = validateEndereco(data); 
  if (!isEnderecoValid && !retirar && !frete_isento) {
    setMsg('Todos os campos de endereço são obrigatórios.');
    setTimeout(() => {
      setMsg(null);
    }, 3000);

    return false;  // Certifique-se de retornar false para interromper a execução
  }

  if (!validate.frete && !retirar && !frete_isento) {
    setMsg('É necessário escolher um frete.');
    setTimeout(() => {
      setMsg(null);
    }, 3000);
    console.log('nao valida frete')
    return false;  // Certifique-se de retornar false para interromper a execução
  }

  // Se tudo estiver validado, retorna true
  return true;
};

const handleSubmit = (data) =>{
  console.log(data)
  if (!validateForm()) {
    return;
    
  }

  async function fastCheckout() {
    
    const formData = {
      ...data,
      frete_retirar:retirar,
      frete_isento:frete_isento,
      cart_data:cart,
      cupom:cupom
      }

    try {
      const response = await GenPost(`api/fast-checkout`, formData);
      if (response?.token){
        setLogging(true);
        localStorage.setItem('token' , response?.token);
      }
      setOrderData(response);
      setPayment(response);

  
    } catch (err) {
      setMsg('Houve algum erro no processamento do seu pedido, tente novamente mais tarde ou entre em contato com nosso atendimento!.');
      setTimeout(() => {
        setMsg(null);
      }, 3000);

    }
  
  }
  fastCheckout();
}

const checkCupom = async (cupom) => {
  try {
    const response = await GenGet(`api/cupoms/check/${cupom.codigo}`);

    if (response.cupom) {
      setCupom({
        ...response.cupom
      });
      setCupomMsg(response.message);
      setCupomValido(true);
    } else {
      setCupomMsg("Cupom inválido");
      setCupomValido(false);
    }
    setTimeout(() => {
      setCupomMsg(null);
    }, 3000);
  } catch (err) {
    setCupomMsg("Cupom inválido");
    setTimeout(() => {
      setCupomMsg(null);
    }, 3000);

  }
}

function handlePrice(data){
  const {cupom, valor_total} = data
  return
}

  if(payment) {

    return(
      <div className='flex shadow rounded min-h-[70vh] justify-center align-middle items-center'>
      <div sx={{padding:1}}>
         <MercadoPago ordem_id={orderData.id} ordem_valor={(parseFloat(orderData.valor_total)).toFixed(2)}/> 
      </div>             
    </div>
    )

  } else {

    return (<>
      {loading ? (
        <div className='p-1 w-full h-[70vh] flex justify-center items-center'>
          <CircularProgress color="success" />
        </div>

      ) : (

        <div className="flex flex-col lg:flex-row p-4 gap-2">
        <div className="flex flex-col w-full p-4 border-2 border-slate-300 rounded-lg">
          <ControladorUser handleuser={setData} data={data} user={data.user} user_ok={user_ok} validate={validate} validauser={setValidate} />
          <ControladorCliente handleCliente={setData} data={data} cliente={data.cliente} cliente_ok={cliente_ok} validate={validate} validaCliente={setValidate} />
          {possuiRetirada && (
          <div className='flex flex-row gap-2 p-2 align-middle items-center'>
              <p className="flex justify-center p-0 m-0 font-semibold">Retirar na loja?</p>
              <input
                className='bg-slate-300 rounded flex h-[100%]'
                id='retirar'
                name='retirar'
                type='checkbox'
                checked={retirar}
                onChange={handleRetirar}
              />
          </div>
          )}
 
          {!retirar ? (<div>
            <ControladorEndereco handleEndereco={setData} data={data} enderecos={enderecos} cliente_ok={cliente_ok} validate={validate} validaEndereco={setValidate} setIsento={setFreteIsento}/>
            {data.endereco && data.endereco.cep && (<ControladorFrete data={data} setData={setData} cliente={data.cliente} CEP={data.endereco.cep} validaFrete={setValidate} isento={frete_isento} setIsento={setFreteIsento}/>)}
            </div>) : <p className="text-wrap p-2">Retirar no endereço ... após a finalização do pedido.</p>}
          
          
        </div>
        <div className="flex flex-col w-full p-4 bg-[#E9CE9F] rounded-md sm:w-[100%]">
        <h3 className='text-[13pt] text-[#121212] font-semibold'>
          Seus Itens
        </h3>
          <div className="bg-slate-100 rounded-md p-2 min-h-[300px] w-full flex flex-col justify-between">
            <div className="flex-grow">
            {cart && cart.length > 0 ? (
                  cart.map(item => (
                    <div className='p-1 flex flex-col bg-slate-200 rounded-md relative' key={item.id}>
                      <div className='flex flex-row gap-1 w-full'>
                        <img src={`${appUrl}/${item.imagem}`} className='w-[60px] h-[60px] rounded-md' alt={item.nome} />
                        <div className='flex-grow flex flex-col'>
                          <p className='font-semibold text-[10pt]'>{item.nome}</p>
                          <p className='font-semibold text-[10pt] pl-1 -mt-4 '>{item.descricao}</p>
                          <p className='-mt-4 text-[10pt] pl-1'>R${item.valor}</p>
                        </div>
                      </div>
                      <div className='w-full p-1 flex flex-row justify-end gap-1'>
                        <p className='font-semibold text-[10pt] pt-[10px] m-0'>quantidade: {item.quantidade}</p>
                        <p className='font-semibold text-[10pt] pt-[10px] m-0'>- total: R${(item.valor * item.quantidade).toFixed(2)}</p>
                      </div>
                      <span className='absolute -top-0 -right-0 bg-red-400 hover:bg-red-300 text-slate-800 shadow-md font-semibold text-[10pt] rounded-full flex justify-center align-middle items-center w-[18px] h-[18px] cursor-pointer' onClick={() => removeItem(item.id, item.produto_id)} aria-label="delete">
                        x
                      </span>
                    </div>
                  ))
                ) : (
                  <p className='font-semibold text-[10pt]'>Carrinho vazio</p>
                )}
            </div>
            <div className="flex flex-row justify-between">
              <div className="text-[11pt]">
                <p className="m-0 p-0 ">subtotal: R${subtotal}</p>
                <p className="m-0 p-0">frete: R${data.frete_valor?data.frete_valor:('0.00')}</p>
                <p className="m-0 p-0">total: R${totalFormatado}</p> 
              </div>
              <div className=""></div>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center flex-wrap">
            <div className="flex flex-row align-middle items-center gap-1 mt-2">
            <input className="w-[130px] h-[30px] rounded-sm" placeholder="Cupom" onChange={handleCupom} value={cupom.codigo}/>
            <button className=' h-[30px] p-1 bg-yellow-500 hover:bg-yellow-400 text-slate-800 font-medium rounded-md '>
              <p className='text-[10pt] p-0 m-0' onClick={()=>checkCupom(cupom)}>Verificar</p>
            </button>
            </div>
          
          <div className="flex flex-row justify-end gap-2 mt-2">
          <button className=' w-[90px] h-[32px] p-1 bg-slate-500 hover:bg-slate-400 text-slate-100 font-semibold rounded-md '>
            <p className='text-[12pt]' onClick={()=>nav('/produtos')}>Cancelar</p>
          </button>
          <button className=' w-[90px] h-[32px] p-1 bg-yellow-500 hover:bg-yellow-400 text-slate-800 font-semibold rounded-md '>
            <p className='text-[12pt]' onClick={()=>handleSubmit(data)}>Finalizar</p>
          </button>
          </div>
          </div>
          {cupomMsg ? 
              <div className={"flex flex-row-reverse p-1"} >
                <Alert severity="info" sx={{width:320}} > {cupomMsg} </Alert>
              </div>
          : null}

          {msg ? 
              <div className={"flex flex-row-reverse p-1"} >
                <Alert severity="info" sx={{width:320}} > {msg} </Alert>
              </div>
          : null}

        </div>
      </div>
      )}
      </>
    )
  }
}
export default FinalizarPedido
